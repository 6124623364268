<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-14 16:12:16
 * @Message: Nothing
-->
<template>
    <div class="home">
        <div class="header-intro">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12">
                        <div class="entry" data-aos="slide-up" data-aos-duration="1500">
                            <h2>
                                欢迎您来到
                                <br />
                                诚唐科技有限责任公司！
                            </h2>
                            <p>
                                作为一家专注于软件开发的公司，我们致力于为客户提供高质量、创新性和定制化的软件解决方案。无论您是需要企业级应用、移动应用开发还是Web应用程序，我们都能满足您的需求。
                            </p>
                            <p>
                                我们深耕软件开发领域多年，拥有一支技术精湛、经验丰富的团队。我们掌握着广泛的编程语言和技术，如Java、React、Node.js等，在各个行业和领域都积累了丰富的项目经验。
                            </p>
                            <p>
                                我们不仅提供软件开发服务，还提供技术咨询、产品设计和测试等全方位的支持。我们的目标是帮助客户实现业务增长、提高效率和竞争力，成就他们的成功之路。
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="entry entry-img" data-aos="zoom-in" data-aos-duration="1500">
                            <img src="../../assets/img/hero.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- services -->
        <div class="services section">
            <div class="container">
                <div class="title-section">
                    <span>Sevices</span>
                    <h2>高效、专业的服务</h2>
                </div>
                <div class="row first-row">
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in">
                            <i class="fab fa-wordpress"></i>
                            <h5>企业系统</h5>
                            <p>综合性的软件系统，旨在帮助企业管理各个方面的业务和信息</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="1500">
                            <i class="fab fa-magento"></i>
                            <h5>业务平台</h5>
                            <p>
                                集成的软件平台，统一的操作界面和功能模块，支持和管理企业的各种业务活动。
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="1500">
                            <i class="fab fa-drupal"></i>
                            <h5>商城</h5>
                            <p>
                                安全、方便、愉快的在线购物平台，让用户购买所需的商品变得更加轻松和满意
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2500">
                            <i class="fas fa-mobile-alt"></i>
                            <h5>App</h5>
                            <p>
                                功能强大、用户友好的移动应用程序，旨在为用户提供各种便捷的服务和体验
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="3000">
                            <i class="fas fa-credit-card"></i>
                            <h5>小程序</h5>
                            <p>
                                无需下载安装即可使用的应用，它实现了应用“触手可及”的梦想，用户扫一扫或者搜一下即可打开应用
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2000">
                            <i class="far fa-chart-bar"></i>
                            <h5>桌面系统</h5>
                            <p>提供多任务处理能力，允许用户同时打开多个应用程序，提高工作效率</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end services -->

        <!-- about us -->
        <div class="about-us section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="entry" data-aos="slide-right" data-aos-duration="1500">
                            <img src="../../assets/img/about-us.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry" data-aos="slide-left" data-aos-duration="1500">
                            <span>About us</span>
                            <h2>10 年一路走来 我们正年轻</h2>
                            <p>
                                成都诚唐科技有限责任公司成立于2014年，注册资金一百万，
                                以独特的视角诠释品牌的内涵 , 打造企业形象 , 为客户提供互动营销咨询
                                、策划、设计、制作、开发、维护到营销整合等解决方案 ,
                                深耕软件外包服务。
                            </p>

                            <button
                                class="button"
                                @click="
                                    () => {
                                        this.$router.push({ path: '/about' });
                                    }
                                "
                            >
                                更多
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end about us -->
    </div>
</template>

<script>
export default {
    name: "home",
    mounted() {},
};
</script>

<style scoped></style>
